.grid-wrap
  position: relative
  
.grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-auto-flow: column
  width: 100%
  height: auto
  box-sizing: border-box
  // background-color: rgba(0, 0, 0, 0.04)
  // background-color: rgba(224, 224, 224, 1)
  // border-right: 1px solid #E0E0E0
  // border-bottom: 1px solid #E0E0E0

  &-item
    position: relative
    display: flex
    align-items: center
    justify-content: center
    font-size: 14px
    cursor: pointer
    user-select: none
    // background-color: #f5f5f5
    // border: 1px solid transparent
    transition: border-color 140ms ease, background-color 140ms ease

    &.selected
      background-color: rgba(0, 119, 207, 0.04)
      //border-color: rgba(0, 119, 207, 1)
      
      &::before, &::after
        z-index: 2
        border-color: rgba(0, 119, 207, 1)

    &.hidden
      opacity: 0
      pointer-events: none

    &::before,
    &::after
      content: ''
      position: absolute
      // background-color: #E0E0E0
      background: transparent
      left: 0
      top: 0
      z-index: 1

    &::before
      width: 100%
      height: 100%
      border-top: 1px solid #E0E0E0
      border-left: 1px solid #E0E0E0

    &::after
      right: -1px
      bottom: -1px
      border-right: 1px solid #E0E0E0
      border-bottom: 1px solid #E0E0E0


.merge-button
  // position: absolute
  // top: 100%
  // bottom: -20px

  border-radius: 50%
  border: none
  padding: 8px
  display: flex
  justify-content: center
  align-content: center
  cursor: pointer
  z-index: 3
  background-color: rgb(0, 119, 207)

  &-disabled
    background-color: #E0E0E0

.split-button
  position: absolute
  top: 50%
  transform: translateY(-50%)
  border-radius: 50%
  background-color: #f1f5f8
  border: none
  padding: 8px
  display: flex
  justify-content: center
  align-content: center
  cursor: pointer
  z-index: 3


.drag-handle-wrap
  display: flex
  align-items: center
  justify-content: center
  font-size: 14px
  user-select: none


.dragging
  opacity: 0.5

.drag-over
  position: relative

.drag-over::after
  content: ''
  position: absolute
  left: 0
  right: 0
  top: -1px
  height: 2px
  z-index: 1
  background-color: rgba(0, 119, 207, 1)

.grid-overlay-dnd
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 3
  margin-top: 32px
  display: flex
  flex-direction: column

.selection-controls-wrap
  position: absolute