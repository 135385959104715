.wrap
  // background: url('/assets/icons/machine-config-bg.svg') repeat-y
  // background-size: auto 20px
  height: 100%
  width: 100%
  padding: 26px 88px
  position: relative
  
.grid
  display: grid
  // grid-template-columns: repeat(2, 1fr)
  // grid-auto-flow: column
  width: 100%
  height: auto
  background-color: #fff
  position: relative
  border-bottom: 1px solid #000

  &::after
    content: ''
    position: absolute
    background: transparent
    right: 0
    top: 0
    bottom: 0
    z-index: 1
    width: 1px
    background: #000

  &-item
    position: relative
    display: flex
    align-items: center
    justify-content: center
    font-size: 14px
    cursor: pointer
    user-select: none
    background-color: #fff
    // border: 1px solid transparent
    // height: 80px
    transition: border-color 140ms ease, background-color 140ms ease
    transform-style: preserve-3d

    &:not(:last-child)
      border-right: 1px solid #000
      

    &.selected, &:hover
      background-color: #f1f5f8
      //border-color: rgba(0, 119, 207, 1)
      z-index: 2
      
      &::before, &::after
        z-index: 2
        border-color: rgba(0, 119, 207, 1)
    
    &.selected_layout
      background-color: #f1f5f8
      &::before, &::after
        z-index: 2
        border-color: rgba(0, 119, 207, 1)

    &.hidden
      opacity: 0
      pointer-events: none

    &::before,
    &::after
      content: ''
      position: absolute
      background: transparent
      left: 0
      top: 0
      z-index: 1

    &::before
      width: 100%
      height: 100%
      left: -1px
      top: -1px
      border-top: 1px solid transparent
      border-left: 1px solid transparent

    &::after
      right: -1px
      bottom: -1px
      border-right: 1px solid transparent
      border-bottom: 1px solid transparent


.carousel
  &:hover
    .scroll
      opacity: 1
      pointer-events: all

.scroll
  position: absolute
  top: 50%
  transform: translateY(-50%)
  border-radius: 100px  
  border: 1px solid rgba(0,0,0,.12)
  background-color: #fff
  display: flex
  justify-content: center
  align-items: center
  padding: 5px
  gap: 8px
  z-index: 5
  opacity: 0
  pointer-events: none
  transition: opacity 180ms ease
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)

  svg 
    width: 20px
    height: 20px
    cursor: pointer
    border-radius: 50%
    &:hover
      background-color: rgba(0,0,0,.12)
    path
      fill: rgba(0,0,0,.56)

  &_left
    left: -34px
  &_right
    right: -34px

.scroll_global
  position: absolute
  top: 50%
  transform: translateY(-50%)
  border-radius: 4px
  border: 1px solid rgba(0,0,0,.12)
  background-color: #fff
  display: flex
  justify-content: center
  align-items: center
  // padding: 5px
  gap: 8px
  z-index: 5
  transition: opacity 180ms ease
  // box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)
  cursor: pointer
  user-select: none

  svg 
    width: 20px
    height: 20px
    pointer-events: none
    path
      fill: rgba(0,0,0,.56)

  &_left
    left: -80px
  &_right
    right: -80px


.label
  top: 4px
  left: 4px
  font-size: 10px
  position: absolute
  transition: opacity 140ms ease
  pointer-events: none
  z-index: 4

.number
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  width: 40px
  height: 100%
  background-color: #fff
  
  &::before
    content: ''
    position: absolute
    background: transparent
    left: 0
    top: 0
    z-index: 1
    width: 100%
    top: 0
    bottom: -1px
    border-top: 1px solid #000
    border-bottom: 1px solid #000

  &.left
    left: -40px
    &::before
      border-left: 1px solid #000
  &.right
    right: -40px
    &::before
      border-right: 1px solid #000


.row-number, .row-fullfillment
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  width: 40px
  height: 100%
  background-color: #fff
  font-size: 14px
  top: 0
  
  &::before
    content: ''
    position: absolute
    background: transparent
    left: 0
    top: 0
    z-index: 1
    width: 100%
    top: 0
    bottom: -1px
    border-top: 1px solid #000
    border-bottom: 1px solid #000

.row-number
  right: -40px
  &::before
    border-left: unset
    border-right: 1px solid #000
  
.row-fullfillment
  // display: none
  // position: relative
  left: -40px
  &::before
    border-left: 1px solid #000

  &-bg
    position: absolute
    bottom: 0
    width: 100%
    background-color: #E5F6FD
    // z-index: -1

.actions
  position: absolute
  top: 10px
  right: 8px
  display: flex
  flex-direction: column
  align-items: flex-end
  width: 38px
  gap: 16px
  height: 400px
  z-index: 2

  .reset
    display: flex

  button
    width: 36px
    &:hover
      background-color: #fff