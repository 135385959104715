.wrap
  // background: url('/assets/icons/machine-config-bg.svg') repeat-y
  // background-size: auto 20px
  height: 100%
  width: 100%
  padding: 26px 88px
  position: relative
  
.grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-auto-flow: column
  width: 100%
  height: auto
  background-color: #fff

  &-item
    position: relative
    display: flex
    align-items: center
    justify-content: center
    font-size: 14px
    cursor: pointer
    user-select: none
    background-color: #fff
    // border: 1px solid transparent
    transition: border-color 140ms ease, background-color 140ms ease

    &.selected, &:hover
      background-color: #f1f5f8
      //border-color: rgba(0, 119, 207, 1)
      
      &::before, &::after
        z-index: 2
        border-color: rgba(0, 119, 207, 1)
    
    &.selected_layout
      background-color: #f1f5f8
      &::before, &::after
        z-index: 2
        border-color: rgba(0, 119, 207, 1)

    &.hidden
      opacity: 0
      pointer-events: none

    &::before,
    &::after
      content: ''
      position: absolute
      background: transparent
      left: 0
      top: 0
      z-index: 1

    &::before
      width: 100%
      height: 100%
      border-top: 1px solid #000
      border-left: 1px solid #000

    &::after
      right: -1px
      bottom: -1px
      border-right: 1px solid #000
      border-bottom: 1px solid #000

.number
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  width: 40px
  height: 100%
  background-color: #fff
  
  &::before
    content: ''
    position: absolute
    background: transparent
    left: 0
    top: 0
    z-index: 1
    width: 100%
    top: 0
    bottom: -1px
    border-top: 1px solid #000
    border-bottom: 1px solid #000

  &.left
    left: -40px
    &::before
      border-left: 1px solid #000
  &.right
    right: -40px
    &::before
      border-right: 1px solid #000

.actions
  position: absolute
  top: 10px
  right: 8px
  display: flex
  flex-direction: column
  align-items: flex-end
  width: 38px
  gap: 16px
  height: 400px
  z-index: 2

  .reset
    display: flex

  button
    width: 36px
    &:hover
      background-color: #fff