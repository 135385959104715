

.container
  flex-grow: 1
  display: flex
  background-color: #fff
  background: url('/assets/icons/machine-config-bg.svg') repeat-y
  background-size: auto 20px
  height: 100%

  // @media (min-width: 900px)
  //   grid-template-columns: 28% 44% 28%

  // @media (min-width: 1536px)
  //   grid-template-columns: 20% 60% 20%

.center
  flex: 1
  overflow-y: auto

  // @media (min-width: 900px)
  //   flex: 1 0 44%

  // @media (min-width: 1536px)
  //   flex: 1 0 60%

.sidebar
  background-color: #fff
  position: relative
  transition: transform 300ms ease, flex-basis 300ms ease

  flex: 0 0 28%
  max-width: 28%
  
  .content
    position: relative
    height: 100%
    overflow-y: auto

  @media (min-width: 900px)
    flex: 0 0 25%
    max-width: 25%

  @media (min-width: 1536px)
    flex: 0 0 20%
    max-width: 20%

  &:hover .collapse, &:hover .collapse_left
    opacity: 1
    pointer-events: all

  &.collapsed
    flex: 0 0 72px
    // max-width: 72px
    .content
      opacity: 0
      position: absolute
      top: 0
      left: 0
      width: max-content
      pointer-events: none
      z-index: -1

  .collapse, .collapse_left
    position: absolute
    top: 50%
    right: 0
    transform: translate(60%, -50%)
    width: 40px
    height: 40px
    border-radius: 50%
    background-color: #fff

    transition: opacity 160ms ease

    opacity: 0
    pointer-events: none
  .collapse_left
    transform: translate(-60%, -50%)
    left: 0
    right: unset

