.wrap
  padding-top: 16px
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(74px, 1fr))
  gap: 8px
  min-height: 500px

.card-wrap
  user-select: none

.card
  aspect-ratio: 1
  position: relative
  padding: 6px
  border-radius: 4px
  border: 1px solid rgba(0, 0, 0, 0.12)
  display: flex
  justify-content: center
  align-items: center
  cursor: grab
  transition: background-color 140ms ease, border-color 140ms ease

  &-image
    max-width: 70%
    max-height: 70%
    width: auto
    height: auto
    object-fit: contain
    user-select: none

  &:hover
    border-color: rgba(0, 119, 207, 1)
    background-color: rgba(0, 117, 207, 0.04)
    
    .sku, .quantity
      opacity: 0
    
    .move_to_machine, .edit, .delete
      opacity: 1

  &.dragged
    border: 1px solid rgba(0, 0, 0, 0.12)
    background-color: #0000000a
    
    .sku, .quantity
      opacity: 0
    
    .move_to_machine, .edit, .delete
      opacity: 0

  .sku, .quantity
    font-size: 10px
    position: absolute
    transition: opacity 140ms ease
    pointer-events: none

  .sku
    left: 4px
    bottom: 4px
  
  .quantity
    top: 4px
    right: 4px
  
  .description-crop
    // white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    font-size: 10px
    line-height: 1.2
    max-height: 3.6em
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    text-align: center

  .move_to_machine
    position: absolute
    top: 4px
    left: 4px
    cursor: pointer
    opacity: 0
  .edit
    position: absolute
    top: 4px
    right: 4px
    cursor: pointer
    opacity: 0

  .delete
    position: absolute
    bottom: 0px
    right: 4px
    cursor: pointer
    opacity: 0
    
    

