
.wrap
  height: 100%
  width: 100%
  padding: 26px 70px
  position: relative


.grid
  position: relative
  display: grid
  width: 100%
  height: auto
  background-color: #fff
  border-bottom: 1px solid #000
  grid-template-rows: repeat(6, 100px)

  // &::after
  //   content: ''
  //   position: absolute
  //   background: transparent
  //   right: 0
  //   top: 0
  //   bottom: 0
  //   z-index: 1
  //   width: 1px
  //   background: #000

.row
  position: relative
  // display: grid
  // grid-template-rows: 100px


.grid-item
  position: relative
  display: flex
  align-items: center
  justify-content: center
  font-size: 14px
  cursor: pointer
  user-select: none
  background-color: #fff
  transition: border-color 140ms ease, background-color 140ms ease
  transform-style: preserve-3d
  height: 100%
  width: 100%

  &.selected, &:hover
    // z-index: 1
    background-color: #f1f5f8
    //border-color: rgba(0, 119, 207, 1)
    .dnd-handle
      opacity: 1
      pointer-events: all
      z-index: 4
    
    .label
      opacity: 0

    &::before, &::after
      // z-index: 2
      border-color: rgba(0, 119, 207, 1)
  
  &.selected_layout
    background-color: #f1f5f8
    &::before, &::after
      // z-index: 2
      border-color: rgba(0, 119, 207, 1)

  &.hidden
    opacity: 0
    pointer-events: none

  &::before,
  &::after
    content: ''
    position: absolute
    background: transparent
    left: 0
    top: 0
    z-index: 1

  &::before
    width: 100%
    height: 100%
    border-top: 1px solid #000
    border-left: 1px solid #000

  &::after
    right: -1px
    bottom: -1px
    border-right: 1px solid #000
    border-bottom: 1px solid #000

  .dnd-handle
    position: absolute
    top: 4px
    left: 4px
    cursor: pointer
    opacity: 0
    pointer-events: none
    // z-index: 10

.label
  top: 4px
  left: 4px
  font-size: 10px
  position: absolute
  transition: opacity 140ms ease
  pointer-events: none
  z-index: 4

.cell_coils
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  padding: 0 10px
  display: flex
  align-items: center
  justify-content: space-evenly

  > svg
    height: auto


.row-number, .row-fullfillment
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  width: 40px
  height: 100%
  background-color: #fff
  font-size: 14px
  top: 0
  
  &::before
    content: ''
    position: absolute
    background: transparent
    left: 0
    top: 0
    z-index: 1
    width: 100%
    top: 0
    bottom: -1px
    border-top: 1px solid #000
    border-bottom: 1px solid #000

.row-number
  left: -40px
  &::before
    border-right: unset
    border-left: 1px solid #000
  
.row-fullfillment
  left: -40px
  &::before
    border-left: 1px solid #000


.bin-occupancy
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  
  &-bg
    position: absolute
    bottom: 0
    width: 100%
    background-color: rgba(0,0,0, 0.08)


.actions
  position: absolute
  top: 10px
  right: 8px
  display: flex
  flex-direction: column
  align-items: flex-end
  width: 38px
  gap: 16px
  height: 400px
  z-index: 2

  .reset
    display: flex

  button
    width: 36px
    &:hover
      background-color: #fff


.test > *:nth-child(11)
  // display: none !important