
.wrap
  width: 375px
  padding: 16px
  display: flex
  flex-direction: column
  gap: 16px

.title
  display: flex
  justify-content: space-between
  align-items: center