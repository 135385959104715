.wrap

.carousel-sector
  &:hover
    fill: rgba(0, 119, 207, 0.04)

.row
  padding-right: 20px
  padding-bottom: 8px

  &_heading
    position: relative
    display: flex
    align-items: center
    padding-bottom: 8px
    justify-content: space-between

.expand
  position: absolute
  top: 0
  left: 100%
  width: 20px
  cursor: pointer
  
.merge-button
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

  border-radius: 50%
  border: none
  padding: 8px
  display: flex
  justify-content: center
  align-content: center
  cursor: pointer
  z-index: 3
