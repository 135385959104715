.wrap
  dispaly: flex
  flex-direction: column

.item
  position: relative
  // padding: 6px
  display: flex
  justify-content: center
  align-items: center
  cursor: grab
  
  .description-cropped
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.row
  display: grid
  grid-template-columns: 30% auto 30px 20px
  font-size: 14px

  > *
    padding: 16px 8px
    overflow: hidden

  @media (min-width: 1728px)
    // grid-template-columns: 25% 55% 20%
    grid-template-columns: 30% auto 30px 20px

  .quantity
    padding: 0

  .edit
    padding: 0
    height: 20px

    > svg
      width: inherit
      height: inherit