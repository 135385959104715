.wrap
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr))
  width: 100%
  height: 100%

  --fill-height: 0%
  position: relative // Needed for absolute positioning of ::before

  &::before
    content: ""
    position: absolute
    height: var(--fill-height)
    background: rgba(0,0,0,0.04)
    bottom: 0
    left: 1px
    right: 0px

.item
  height: 100%
  max-width: 100%
  max-height: 100%

.card
  position: relative
  padding: 6px
  display: flex
  justify-content: center
  align-items: center
  cursor: grab
  height: 100%
  font-size: 10px

  background-repeat: no-repeat
  background-size: contain
  background-position: center
  background-origin: content-box

  &::before,
  &::after
    content: ''
    position: absolute
    background: transparent
    left: 0
    top: 0
    z-index: 1

  &::before
    width: 100%
    height: 100%

  &::after
    right: -1px
    bottom: -1px
    border-right: 1px solid #0000001f
    border-bottom: 1px solid #0000001f
  

  &:hover
    // border-color: rgba(0, 119, 207, 1)
    background-color: rgba(0, 117, 207, 0.04)
    
    .sku, .quantity
      opacity: 0
    
    .move_to_lobby
      opacity: 1

  &.dragged
    background-color: #0000000a
    
  //   .sku, .quantity
  //     opacity: 0
    
  //   .move_to_machine, .edit
  //     opacity: 0

  .sku, .quantity
    font-size: 10px
    position: absolute
    transition: opacity 140ms ease
    pointer-events: none
    z-index: 1

  .sku
    left: 4px
    bottom: 4px
    z-index: 1
  
  .quantity
    top: 4px
    right: 4px
    z-index: 1
  
  .description-crop
    overflow: hidden
    text-overflow: ellipsis
    font-size: 10px
    line-height: 1.2
    max-height: 3.6em
    max-width: 100%
    display: -webkit-box
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    word-break: break-word
    text-align: center

  .move_to_lobby
    position: absolute
    top: 4px
    right: 4px
    cursor: pointer
    opacity: 0
    z-index: 3

.additional-items-tooltip
  
  > div
    border-radius: 0

.additional-items
  display: grid
  grid-template-columns: repeat(2, 95px)
  grid-auto-rows: 58px

.filled
  background: #ddd
  