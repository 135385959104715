.row
  padding-bottom: 8px

  &_heading
    position: relative
    display: flex
    align-items: center
    padding-bottom: 16px
    justify-content: space-between

.expand
  cursor: pointer

.cell
  padding-left: 30px
  position: relative
  display: flex
  align-items: center
  padding-bottom: 16px
  justify-content: space-between

  &_fields
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 16px
    padding-left: 16px